<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <p><span class="text-muted">Task Note: </span> <span style="color: #303030; font-weight: 600;">{{ $i18n.locale === 'bn' ? item.note_full_bn : item.note_full }}</span></p>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'TaskAssignmentDetails',
    props: ['item']
}
</script>
