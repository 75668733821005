<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('irrigation_task.assigned_task') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes" @returned-data="searchData"/>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('irrigation_task.assigned_task') + ' ' + $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(complete_type_id)="data">
                                            {{ getCompleteStatus(data.item.complete_type_id) }}
                                        </template>
                                        <template v-slot:cell(is_verified)="data">
                                            {{ data.item.is_verified ? $t('globalTrans.yes') : $t('globalTrans.no') }}
                                        </template>
                                        <template #cell(attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download>{{ $t('globalTrans.link') }}</a>
                                            <small class="text-muted" v-else>{{ $t('globalTrans.noAttachment') }}</small>
                                        </template>
                                        <template #cell(note)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(schedule)="data">
                                            <span v-if="data.item.task_from">{{ $t('globalTrans.from') }}: {{ data.item.task_from | dateFormat }}</span><br>
                                            <span v-if="data.item.task_to">{{ $t('irrigation_task.to') }}: {{ data.item.task_to | dateFormat }}</span>
                                            <span class="text-muted" v-else>
                                                N/A
                                            </span>
                                        </template>
                                        <template v-slot:cell(created_at)="data">
                                            <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFromNow }}</span>
                                        </template>
                                        <template #cell(note_bn)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Active" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Inactive" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="dataChange"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-4" size="xl" :title="formTitle" :hide-footer=true :ok-title="$t('globalTrans.close')">
            <FormV :id="testId" :key="testId" @submitted="dataChange"/>
        </b-modal>
        <b-modal id="view-modal" size="lg" :title="$t('irrigation_task.task_details')" :hide-footer=true>
            <Details :item="currentItem"/>
        </b-modal>
    </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { taskAssignList, taskAssignToggleStatus } from '../../api/routes'
import { List, Common, helpers } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'
import Details from './Details'
import { mapGetters } from 'vuex'

export default {
    name: 'ListView',
    mixins: [List, Common],
    components: {
        FormV, FormSearch, Details
    },
    data () {
        return {
            search: {
                task_name: '',
                org_id: '',
                assign_user_id: '',
                task_type_id: ''
            },
            testId: 0,
            rows: [],
            defaultNull: '',
            currentItem: null
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        taskTypeList () {
            return this.$store.state.commonObj.irrigationTaskTypeList
        },
        orgList () {
            return this.$store.state.commonObj.organizationProfileList
        },
        formTitle () {
            return (this.testId === 0) ? this.$t('irrigation_task.assign_task') + ' ' + this.$t('globalTrans.entry') : this.$t('irrigation_task.assign_task') + ' ' + this.$t('globalTrans.modify')
        },
        listData () {
            return this.$store.state.IrriTask.taskAssignList
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('org_pro.organization'), class: 'text-center' },
                { label: this.$t('irrigation_task.task_name'), class: 'text-center' },
                { label: this.$t('irrigation_task.task_for'), class: 'text-center' },
                { label: this.$t('irrigation_task.responsible'), class: 'text-center' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('globalTrans.schedule'), class: 'text-center expand-column' },
                { label: this.$t('irrigation_task.note'), class: 'text-center' },
                { label: this.$t('globalTrans.verified'), class: 'text-center' },
                { label: this.$t('pump_install.created_date'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'task_name' },
                { key: 'task_type' },
                { key: 'assign_username' },
                { key: 'attachment' },
                { key: 'schedule' },
                { key: 'note' },
                { key: 'is_verified' },
                { key: 'created_at' },
                { key: 'complete_type_id' },
                { key: 'action' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'org_name_bn' }
                keys[2] = { key: 'task_name_bn' }
                keys[3] = { key: 'task_type_bn' }
                keys[7] = { key: 'note_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        searchAttributes () {
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'org_id',
                        type: 'Select',
                        label: 'org_pro.organization',
                        options: this.orgList,
                        selectTitle: this.$t('globalTrans.select'),
                        value: this.search.org_id
                    },
                    {
                        name: 'task_type_id',
                        type: 'Select',
                        label: 'irrigation_task.task_type',
                        options: this.taskTypeList,
                        selectTitle: this.$t('globalTrans.select')
                    },
                    {
                        name: 'task_name',
                        type: 'Input',
                        label: 'irrigation_task.task_name'
                    },
                    {
                        name: 'assign_user_id',
                        type: 'Input',
                        label: 'irrigation_task.responsible'
                    }
                ]
            }
        }
    },
    mounted () {
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.org_id
            })
        } else if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.office_detail.org_id
            })
        }
        this.loadData()
    },
    methods: {
        getCompleteStatus (status) {
            let returnStatus = this.isBn ? 'বিচারাধীন' : 'Pending'
            if (status) {
                const statusList = Object.freeze(this.$store.state.IrriTask.taskReportStatusList)
                const foundStatus = statusList.find(el => el.value === status)
                if (foundStatus) {
                    returnStatus = this.isBn ? foundStatus.text_bn : foundStatus.text_en
                }
            }
            return returnStatus
        },
        view (item) {
            this.currentItem = item
            this.$bvModal.show('view-modal')
        },
        resetId () {
            this.testId = 0
        },
        dataChange () {
            if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.org_id
                })
            } else if (this.authUser.role_id === 0) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.office_detail.org_id
                })
            }
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                editable: false
            }
        },
        async searchData (payload) {
            if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.org_id
                })
            } else if (this.authUser.role_id === 0) {
                this.search = Object.assign({}, this.search, {
                    org_id: this.authUser.office_detail.org_id
                })
            }
            this.search = payload
            await this.loadData()
        },
        toggleStatus (item) {
            this.mixinToggleStatus(irriSchemeServiceBaseUrl, `${taskAssignToggleStatus}/${item.id}`, 'IrriTask/updateIrriTaskStatus', item)
        },
        loadData () {
            this.startLoading()
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            RestApi.getData(irriSchemeServiceBaseUrl, taskAssignList, params).then(async (response) => {
                if (response.success) {
                    const data = await this.dataList(response)
                    if (typeof data.data !== 'undefined') {
                        const listData = data.data.filter(item => typeof item !== 'undefined')
                        await this.$store.dispatch('IrriTask/irriTaskList', listData)
                        this.paginationData(data.pagination)
                    }
                }
                this.finishLoading()
            }).catch((error) => {
                if (error.response) {
                    this.finishLoading()
                }
            })
        },
        async dataList (response) {
            // Formatted object to fit in helper-function
            const orgList = this.$store.state.commonObj.organizationProfileList
            const filePathPrefix = irriSchemeServiceBaseUrl + 'uploads/task-assign-tasks/original/'
            const listData = await response.data.data.map(item => {
                const orgTmp = orgList.find(orgItem => orgItem.value === item.org_id)
                if (typeof orgTmp !== 'undefined') {
                    const taskTypeTmp = this.taskTypeList.find(type => type.value === item.task_type_id)
                    const data = {
                        org_name: orgTmp.text_en,
                        org_name_bn: orgTmp.text_bn,
                        task_type: taskTypeTmp.name,
                        task_type_bn: taskTypeTmp.name_bn,
                        attachment: String(item.attachment) !== 'null' ? filePathPrefix + item.attachment : null,
                        note: helpers.stringLimit(item.note, 10),
                        note_bn: helpers.stringLimit(item.note_bn, 10),
                        note_full: item.note,
                        note_full_bn: item.note_bn
                    }
                    return Object.assign(item, data)
                }
            })
            delete response.data.data
            return {
                data: listData,
                pagination: response.data
            }
        }
    }
}
</script>

<style>
    .expand-column {
        min-width: 160px;
    }
</style>
