<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"
                                        enctype="multipart/form-data">
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Organization Name" vid="org_id"
                                                        rules="required|min:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.organization') }} <span
                                                class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="irrigationTask.org_id"
                                                id="org_id"
                                                :options="orgList"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="defaultNull">
                                                        {{ $t('globalTrans.select') }}
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Task Type" vid="task_type_id"
                                                        rules="required|min:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="task_type_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('irrigation_task.task_type') }} <span
                                                class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="irrigationTask.task_type_id"
                                                id="task_type_id"
                                                :options="taskTypeList"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="defaultNull">
                                                        {{ $t('globalTrans.select') }}
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Task Name (En)" vid="task_name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="task_name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('irrigation_task.task_namef') }} <span
                                                class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="task_name"
                                                v-model="irrigationTask.task_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg="6" sm="12">
                                    <ValidationProvider :name="taskFor.name"
                                                        :vid="taskFor.name"
                                                        rules="required"
                                                        v-if="selectedTaskFor"
                                    >
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="assign_user_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.select') + ' ' + taskFor.name }} <span
                                                class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                :disabled="assignUserLoad"
                                                plain
                                                v-model="irrigationTask.assign_user_id"
                                                id="assign_user_id"
                                                :options="assignToUserList"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="defaultNull">
                                                        {{ assignUserLoad ? 'Loading...' : $t('globalTrans.select') }}
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Task Name (Bn)" vid="task_name_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="task_name_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{ $t('irrigation_task.task_name_bn') }} <span
                                                class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="task_name"
                                                v-model="irrigationTask.task_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Task Note (En)" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="note"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('irrigation_task.notef')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                                id="note"
                                                v-model="irrigationTask.note"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                placeholder="Enter something..."
                                                rows="3"
                                                max-rows="6"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Task Note (Bn)" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="note_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('irrigation_task.note_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                                id="note_bn"
                                                v-model="irrigationTask.note_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                placeholder="Enter something..."
                                                rows="3"
                                                max-rows="6"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="FromDate" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="date"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label> {{ $t('globalTrans.from_date') }} <span
                                                class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                class="date-picker"
                                                v-model="irrigationTask.task_from"
                                                placeholder="Select Date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="ToDate" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            label-for="date"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label> {{ $t('globalTrans.to_date') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                class="date-picker"
                                                v-model="irrigationTask.task_to"
                                                placeholder="Select Date"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Attachment" vid="attachment" rules="required|size:2048|ext:png,jpeg,jpg,pdf">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="3"
                                            :label="$t('globalTrans.attachment')"
                                            label-for="attachment"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.attachment')}} (Max size: 2048 KB) <span class="text-danger">*</span>
                                                </template>
                                            <b-form-file
                                                v-on:change="onFileChange"
                                                v-model="irrigationTask.attachment"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
            </template>
        </iq-card>
    </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { taskAssignStore, taskAssignUpdate, getUserList } from '../../api/routes'
import { Common, Repository } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'

export default {
    name: 'TaskAssignmentForm',
    props: ['id'],
    mixins: [Common],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList
        },
        taskTypeList () {
            const listObject = this.getTaskType()
            return this.filterEnBn(listObject)
        },
        assignToUserList () {
            return this.$store.state.IrriTask.taskAssignToUserList
        }
    },
    data () {
        return {
            loading: false,
            taskFor: {
                name: '',
                Id: ''
            },
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            defaultNull: '',
            irrigationTask: {
                org_id: '',
                task_name: '',
                task_name_bn: '',
                task_type_id: '',
                assign_user_id: '',
                assign_username: '',
                note: '',
                note_bn: '',
                attachment: [],
                is_verified: 1,
                schedule: '',
                task_from: '',
                task_to: ''
            },
            selectedTaskFor: null,
            attachment: null,
            assignUserLoad: false
        }
    },
    watch: {
        'irrigationTask.task_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getUser(newVal)
                this.selectedTaskFor = newVal ? this.getTaskType(newVal) : null
            }
        },
        'irrigationTask.assign_user_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                const user = this.assignToUserList.find(item => item.value === newVal)
                this.irrigationTask.assign_username = typeof user.text !== 'undefined' ? user.text : ''
            }
        },
        selectedTaskFor (newVal, oldVal) {
            this.taskFor.name = (this.$i18n.locale === 'bn' ? newVal.name_bn : newVal.name)
        }
    },
    mounted () {
        core.index()
        flatpickr('.date-picker', {})
    },
    methods: {
        // id = 1 (Operator)
        // id = 2 (Official)
        async getUser (id) {
            this.assignUserLoad = true
            if ([1, 2].includes(id)) {
                let params = ''
                if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
                    params = {
                        org_id: this.authUser.org_id,
                        office_id: this.authUser.office_id
                    }
                } else if (this.authUser.role_id === 0) {
                    params = {
                        upazilla_id: this.authUser.office_detail.upazilla_id,
                        office_id: this.authUser.office_id
                    }
                }
                const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${getUserList}/${id}`, params)
                if (result.success) {
                    let userList = ''
                    if (id === 1) {
                        userList = result.data.map((item) => {
                            return { value: item.id, text: item.username, name: item.name, name_bn: item.name_bn }
                        })
                    } else {
                        userList = result.data.map((item) => {
                            return { value: item.id, text: (this.$i18n.locale === 'bn' ? item.name_bn : item.name) }
                        })
                    }
                    this.$store.commit('IrriTask/addTaskAssignTo', userList)
                } else {
                    this.$store.commit('IrriTask/addTaskAssignTo', [])
                }
                this.assignUserLoad = false
            }
        },
        onFileChange (e) {
            this.attachment = e.target.files[0]
        },
        filterEnBn (dataArray) {
            if (!Array.isArray(dataArray)) {
                return null
            }
            return dataArray.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.name_bn }
                } else {
                    return { value: obj.value, text: obj.name }
                }
            })
        },
        async createData () {
            this.loading = true
            const vm = this
            // const response = await Repository.create({
            //     data: this.irrigationTask,
            //     baseURL: irriSchemeServiceBaseUrl,
            //     storeAPI: taskAssignStore,
            //     updateAPI: taskAssignUpdate,
            //     itemId: this.id,
            //     onSuccess: function () {
            //         vm.$emit('submitted')
            //         // push notification
            //         const notification = response.notification
            //         this.$socket.emit('send-notification', notification)
            //     }
            // })
            await Repository.create({
                data: this.irrigationTask,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: taskAssignStore,
                updateAPI: taskAssignUpdate,
                itemId: this.id,
                onSuccess: function () {
                    vm.$emit('submitted')
                    // push notification
                    // const notification = response.notification
                    // this.$socket.emit('send-notification', notification)
                }
            })
            this.loading = false
        },
        getTaskType (typeId = null) {
            var data = this.$store.state.commonObj.irrigationTaskTypeList

            if (typeId) {
                data = data.filter((item) => item.value === typeId).shift()
            }

            return data
        }
    }
}
</script>
